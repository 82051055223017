@import '../../styles/partials/var';
@import '../../styles/partials/mixins';

main#profile-section {
    min-height: calc(100dvh - 80px);
    padding-block: 0 3rem;
    section#user-info {
        padding: 3rem 0;
        display: flex;
        align-items: center;
        gap: 2rem;

        figure {
            position: relative;
            width: 15%;
            height: fit-content;
            aspect-ratio: 1;
            display: flex;

            img {
                width: 100%;
                aspect-ratio: 1;
                border-radius: 50%;
                object-fit: cover;
            }

            &:hover {
                label {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 100%;
                    height: 100%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    border-radius: 100%;
                    left: 50%;
                    top: 50%;
                    background-color: rgba(#000, 50%);
                    cursor: pointer;
                }
            }

            label {
                display: none;

                svg {
                    width: 50%;
                    height: 50%;
                }
            }
        }

        article {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 3rem;

            div.user-data {
                h2 {
                    margin-bottom: 1rem;
                }
            }

            >.nick {
                font-family: Nasalization;
                font-size: $h1-font-size;
            }
        }

        aside {
            margin-left: auto;
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }

    section#your-sets {
        h2 {
            padding-bottom: 1rem;
        }

        div.inner-sets {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            gap: 2rem;

            > .setsHeader {
                width: 200px;
                min-width: 200px;
                height: 200px;
                transition: all 200ms;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-right: 2px solid white;
                >header{
                    color: $primary-light;
    
                    font-size: 25px;
                    transition: all 200ms;
                    text-align: right;
                    padding-right: 10px;
                    >span{
                        color: $secondary-light;
                        font-size: 40px;
                        font-family: Nasalization;
                    }
                }
            }
    
            >.setsHeader:hover{
                cursor:default;
                >header{
                    font-size: 40px;
                }
            }

            article.one-set {
                cursor: pointer;
                padding: 1rem 0;
                transition: background 0.3s ease-in-out;
                aspect-ratio: 1;
                max-width: 20%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &:nth-of-type(odd) {
                    >.imgBG {
                        background: radial-gradient($primary-dark 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
                    }
                }

                &:nth-last-of-type(even) {
                    >.imgBG {
                        background: radial-gradient($secondary-dark 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
                        }
                }

                >.imgBG {
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    left: 0;
                    top: 0;
                    border-radius: 30px;
                    position:absolute;


                }

                >:not(.imgBG) {
                    z-index: 7;
                }

                >aside {
                    z-index: 100;
                    >h3 {
                        z-index: 8;
                        display:inline;
                    }
                }

                &:hover {
                    >.imgBG {
                        opacity: 1;
                    }
                }
                
                

                figure {
                    width: 100%;
                    height: 200px;

                    display: flex;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        z-index: 1000;
                    }
                }

                aside {
                    padding-top: 2rem;
                    text-align: center;
                }

            }
        }
    }
}
